import React from 'react'
import { Link } from 'gatsby'
import BorderedBox from '../components/borderedbox'
import Card from '../components/card'

// Images
import corporateimage from '../assets/images/careers/Corporate.jpg'
import storeimage from '../assets/images/careers/Stores.jpg'
import hqimage from '../assets/images/careers/Corprate_Careers-HQ_03.jpg'
import storesimage from '../assets/images/careers/Corprate_Careers-Stores_03.jpg'
import dcimage from '../assets/images/careers/Corprate_Careers-DC_11.jpg'
import distroimage from '../assets/images/careers/Distribution.jpg'
import internationalimage from '../assets/images/careers/International.jpg'
import vacaicon from '../assets/images/careers/Corprate_Careers_Icon_06.jpg'
import tagicon from '../assets/images/careers/Corprate_Careers_09.jpg'
import calendaricon from '../assets/images/careers/Careers_US_Internship_Icon_09.jpg'
import bankicon from '../assets/images/careers/Corprate_Careers_Icon_03.jpg'
import appleicon from '../assets/images/careers/Corprate_Careers_Icon_17.jpg'
import handicon from '../assets/images/careers/Corprate_Careers_15.jpg'
import houseicon from '../assets/images/careers/Corprate_Careers_20.jpg'
import busicon from '../assets/images/careers/Corprate_Careers-Bus_Icon.jpg'
import coffeeicon from '../assets/images/careers/Corprate_Careers-Icons-HP_10.jpg'
import gymicon from '../assets/images/careers/Corprate_Careers-Icons-HP_06.jpg'
import steps from '../assets/images/careers/Corprate_Careers-Steps_Icon.jpg'

import iconStyleVolunteer from '../assets/images/careers/iconStyleVolunteer.svg'

const iconStyle = {
  maxHeight: '80px ',
  marginBottom: '10px',
  height: 'auto !important',
  width: 'auto !important',
}

const MBCareers = () => (
  <div>
    <div id="mbcollapseCareers">
      {' '}
      {/* Parent Collapse Container */}
      <div class="row">
        <div id="corporate" class="col-md-6 col-sm-12">
          <Card
            image={corporateimage}
            title="CORPORATE"
            description="Careers at our Secaucus, New Jersey, headquarters offer exciting experiences working alongside the retail industry’s top talent to drive growth and success for the company’s brands: The Children’s Place, Gymboree, Sugar &amp; Jade and PJ PLACE."
          >
            <button
              class="btn btn-primary more-less"
              type="button"
              data-toggle="collapse"
              data-target="#corporateMore"
              aria-expanded="false"
              aria-controls="corporateMore"
            ></button>
          </Card>
        </div>
      </div>
      {/* Corporate More Info */}
      <div
        class="row collapse"
        id="corporateMore"
        data-parent="#mbcollapseCareers"
      >
        <div class="col-12">
          <div class="row no-gutters">
            <div class="col-md-5 col-12 fill d-none d-lg-block">
              <img src={hqimage} className="img-fluid" alt="" />
            </div>
            <div class="col-lg-7 col-md-12">
              <BorderedBox classname="mb-n-border">
                <div class="row mb-5">
                  <div class="col-12">
                    <h2 class="mb-4  text-capitalize">
                      YOUR PLACE IN OUR CORPORATE OFFICE
                    </h2>
                    <p>
                      Careers at our Secaucus, New Jersey, headquarters offer
                      exciting experiences working alongside the retail
                      industry’s top talent to support The Children’s Place and
                      Gymboree brands.{' '}
                    </p>
                  </div>
                </div>
              </BorderedBox>
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-12">
              <BorderedBox classname="mb-n-border">
                <h2>CORPORATE BENEFITS</h2>
                <div class="row justify-content-center">
                  <div class="col-6">
                    <img
                      src={vacaicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Paid Time Off</strong>
                    </p>
                  </div>
                  <div class="col-6">
                    <img
                      src={calendaricon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Summer Fridays</strong>
                    </p>
                  </div>
                  <div class="col-6">
                    <img
                      src={steps}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Professional & Leadership Development</strong>
                    </p>
                  </div>
                  <div class="col-6">
                    <img
                      src={busicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>
                        Free shuttle service to/from Secaucus train station
                      </strong>
                    </p>
                  </div>
                  <div class="col-6">
                    <img
                      src={tagicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Employee Discount Online & In-Store</strong>
                    </p>
                  </div>
                  <div class="col-6">
                    <img
                      src={bankicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>401K – up to 4% company match</strong>
                    </p>
                  </div>
                  {/* <div class="col-6">
                    <img
                      src={appleicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Wellness Reimbursement & Weight Watchers</strong>
                    </p>
                  </div> */}
                  <div class="col-6">
                    <img
                      src={handicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Adoption Assistance</strong>
                    </p>
                  </div>
                  <div class="col-6">
                    <img
                      src={gymicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Free Onsite Gym</strong>
                    </p>
                  </div>
                  <div class="col-6">
                    <img
                      src={coffeeicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Onsite Café & Coffee Bar</strong>
                    </p>
                  </div>
                  <div class="col-6">
                    <img
                      src={houseicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>
                        Free Backup Care (for kids, pets & the elderly)
                      </strong>
                    </p>
                  </div>
                </div>
                <p>
                  <strong class="primary font-italic">PLUS</strong> choice of
                  health insurance plans, company-paid life/AD&D, short and long
                  term disability, a variety of voluntary benefits and an
                  employee assistance program.
                </p>
                <p>
                  **Benefit offerings are subject to change and vary by
                  role/level for each position. Respective waiting periods may
                  apply to certain benefit offerings.
                </p>
                <div class="row mb-2 justify-content-center">
                  <div class="col-md-5 col-12">
                    <a
                      href="https://childrensplace.wd1.myworkdayjobs.com/TCP01"
                      target="_blank"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-block"
                      >
                        APPLY FOR US CORPORATE JOBS
                      </button>
                    </a>
                  </div>
                </div>
              </BorderedBox>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-5">
          <hr class="d-md-none border-top" />
        </div>
      </div>
      {/* End Corporate More Info */}
      <div id="mbcollapseCareers">
        {' '}
        {/* Parent Collapse Container */}
        <div class="row">
          <div class="col-sm-12 d-md-none ">
            <Card
              image={storeimage}
              title="STORES"
              description="Our fleet of stores, reaching across the US &amp; Canada, comprised of more than 10,000 associates deliver great experiences to our customers."
            >
              <button
                class="btn btn-primary more-less"
                type="button"
                data-toggle="collapse"
                data-target="#storeMore"
                aria-expanded="false"
                aria-controls="storeMore"
              ></button>
            </Card>
          </div>
        </div>
      </div>
      {/* Stores More Info */}
      <div class="row collapse" id="storeMore" data-parent="#mbcollapseCareers">
        <div class="col-12">
          <div class="row no-gutters">
            <div class="col-5 fill d-none d-lg-block">
              <img src={storesimage} className="img-fluid" alt="" />
            </div>
            <div class="col-lg-7 col-md-12">
              <BorderedBox classname="mb-n-border">
                <div class="row mb-5">
                  <div class="col-12">
                    <h2 class="mb-4  text-capitalize">
                      YOUR PLACE IN OUR STORES
                    </h2>
                    <p>
                      The Children’s Place stores make shopping for kids and
                      families fast, easy, fun and affordable! We are the PLACE
                      where big fashion meets little prices.
                    </p>
                    <p>
                      Store Managers and Stores Leads, as well as Part-time,
                      Full-time and Seasonal Associate opportunities are
                      available within our fleet of stores across the US &
                      Canada.
                    </p>
                  </div>
                </div>
              </BorderedBox>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              <BorderedBox classname="mb-n-border">
                <h2>STORE BENEFITS</h2>
                {/* <p><strong>Eligible employees can participate in our comprehensive benefits program which includes the following:</strong></p> */}
                <div class="row justify-content-center">
                  <div class="col-md-3 col-6">
                    <img
                      src={vacaicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Paid Time Off</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6">
                    <img
                      src={tagicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Employee Discount Online & In-Store</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6">
                    <img
                      src={bankicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>401K – up to 4% company match</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6 d-md-none">
                    <img
                      src={appleicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Wellness Reimbursement</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6 d-md-none">
                    <img
                      src={handicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Adoption Assistance</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6 d-md-none">
                    <img
                      src={houseicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>
                        Free Backup Care (for kids, pets & the elderly)
                      </strong>
                    </p>
                  </div>
                </div>
                <p>
                  <strong class="primary font-italic">PLUS</strong> choice of
                  health insurance plans, company-paid life/AD&D, short and long
                  term disability, a variety of voluntary benefits and an
                  employee assistance program.
                </p>
                <p>
                  **Benefit offerings are subject to change and vary by
                  role/level for each position. Respective waiting periods may
                  apply to certain benefit offerings.
                </p>
                <div class="row justify-content-center">
                  <div class="col-lg-4 col-12">
                    <a
                      href="https://childrensplace.wd1.myworkdayjobs.com/TCP02"
                      target="_blank"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-block"
                      >
                        APPLY FOR US STORE JOBS
                      </button>
                    </a>
                  </div>
                  <div class="col-lg-4 col-12">
                    <a
                      href="https://childrensplace.wd1.myworkdayjobs.com/TCP05"
                      target="_blank"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-block"
                      >
                        APPLY FOR CANADA STORE JOBS
                      </button>
                    </a>
                  </div>
                </div>
              </BorderedBox>
            </div>
          </div>
          {/* <div class="row no-gutters">
            <div class="col-lg-7 col-md-12">
              <BorderedBox classname="mb-n-border">
                <div class="row mb-5">
                  <div class="col-12">
                    <h2 class="mb-4  text-capitalize">SUPPORTING MILITARY SPOUSES</h2>
                    <p>The Children’s Place makes shopping for kids clothing fast, easy, fun and affordable! We are the PLACE where big fashion meets little prices. Associates at select stores will have the opportunity to work with the Gymboree brand, too!</p>
                    <p>The Children’s Place is a proud partner of the Military Spouse Employment Partnership (MSEP).  We are committed to providing opportunities for military spouses and maintaining employment status for spouses as they relocate.</p>
                  </div>
                </div>
              </BorderedBox>
            </div>
            <div class="col-lg-4 col-12">
              <a href="https://childrensplace.wd1.myworkdayjobs.com/TCP02" target="_blank">
                <button type="button" class="btn btn-outline-primary btn-block">APPLY FOR US STORE JOBS</button>
              </a>
            </div>
            <div class="col-lg-4 col-12">
              <a href="https://childrensplace.wd1.myworkdayjobs.com/TCP05" target="_blank">
                <button type="button" class="btn btn-outline-primary btn-block">APPLY FOR CANADA STORE JOBS</button>
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-5">
          <hr class="d-md-none border-top" />
        </div>
      </div>
      {/* End Stores More Info */}
      <div class="row ">
        <div id="distribution" class="col-md-6 col-sm-12 ">
          <Card
            image={distroimage}
            title="DISTRIBUTION CENTER"
            description="Our state-of-the-art distribution centers process over 250 million packages a year!"
          >
            <button
              class="btn btn-primary more-less"
              type="button"
              data-toggle="collapse"
              data-target="#distributionMore"
              aria-expanded="false"
              aria-controls="distributionMore"
            ></button>
          </Card>
        </div>
      </div>
      {/* Distribution More Info */}
      <div
        class="row  collapse"
        id="distributionMore"
        data-parent="#mbcollapseCareers"
      >
        <div class="col-12">
          <div class="row no-gutters">
            <div class="col-5 fill d-none d-lg-block">
              <img src={dcimage} className="img-fluid" alt="" />
            </div>
            <div class="col-lg-7 col-md-12">
              <BorderedBox classname="mb-n-border">
                <div class="row mb-5">
                  <div class="col-12">
                    <h2 class="mb-4  text-capitalize">
                      YOUR PLACE IN OUR DISTRIBUTION CENTERS
                    </h2>
                    <p>
                      Our state-of-the-art Distribution Centers in Fort Payne,
                      Alabama & Ontario, Canada supply our stores across North
                      America with merchandise as well as fulfill all online
                      orders.{' '}
                    </p>
                  </div>
                </div>
              </BorderedBox>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              <BorderedBox classname="mb-n-border">
                <h2>DISTRIBUTION CENTER BENEFITS</h2>
                <div class="row justify-content-center">
                  <div class="col-md-3 col-6">
                    <img
                      src={vacaicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Generous PTO</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6 d-md-none">
                    <img
                      src={steps}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Professional & Leadership Development</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6">
                    <img
                      src={tagicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Employee Discount Online & In-Store</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6">
                    <img
                      src={bankicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>401K – up to 4% company match</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6 d-md-none">
                    <img
                      src={appleicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Wellness Reimbursement</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6 d-md-none">
                    <img
                      src={handicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>Adoption Assistance</strong>
                    </p>
                  </div>
                  <div class="col-md-3 col-6 d-md-none">
                    <img
                      src={houseicon}
                      className="img-fluid"
                      style={iconStyle}
                      alt=""
                    />
                    <p class="secondary small">
                      <strong>
                        Free Backup Care (for kids, pets & the elderly)
                      </strong>
                    </p>
                  </div>
                </div>
                <p>
                  <strong class="primary font-italic">PLUS</strong> choice of
                  health insurance plans, company-paid life/AD&D, short and long
                  term disability, a variety of voluntary benefits and an
                  employee assistance program.
                </p>
                <p>
                  **Benefit offerings are subject to change and vary by
                  role/level for each position. Respective waiting periods may
                  apply to certain benefit offerings.
                </p>
                <div class="row mb-5 justify-content-center">
                  <div class="col-md-6 col-12">
                    <a
                      href="https://childrensplace.wd1.myworkdayjobs.com/TCP04"
                      target="_blank"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-block text-capitalize"
                      >
                        Apply for US Distribution Jobs
                      </button>
                    </a>
                  </div>
                  <div class="col-md-6 col-12">
                    <a
                      href="https://childrensplace.wd1.myworkdayjobs.com/TCP06"
                      target="_blank"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-block text-capitalize"
                      >
                        Apply for Canada Distribution Jobs
                      </button>
                    </a>
                  </div>
                </div>
              </BorderedBox>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-5">
          <hr class="d-md-none border-top" />
        </div>
      </div>
      {/* End Distribution More Info */}
      <div class="row">
        <div class="col-sm-12 d-md-none ">
          <Card
            classname=""
            image={internationalimage}
            title="INTERNATIONAL"
            description="We have teams working in more than 20 countries in Sourcing, Quality Assurance, IT & more!."
          >
            <button
              class="btn btn-primary more-less"
              type="button"
              data-toggle="collapse"
              data-target="#internationalMore"
              aria-expanded="false"
              aria-controls="internationalMore"
            ></button>
          </Card>
        </div>
      </div>
      {/* International More Info */}
      <div
        class="row collapse text-center "
        id="internationalMore"
        data-parent="#mbcollapseCareers"
      >
        <div class="col-12">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="row mb-3">
                <div class="col-12">
                  <h2 class="mb-3  text-capitalize">
                    YOUR PLACE AROUND THE WORLD
                  </h2>
                  <p>
                    Our International teams work in countries across the globe
                    supporting key initiatives to drive the success of our
                    company globally. We have offices in many major cities,
                    including Hong Kong SAR, China; Shanghai, China; Hyderabad,
                    India; Addis Ababa, Ethiopia and Nairobi, Kenya.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              <h2>INTERNATIONAL BENEFITS</h2>
              <div class="row justify-content-center">
                <div class="col-md-2 col-6">
                  <img
                    src={vacaicon}
                    className="img-fluid"
                    style={iconStyle}
                    alt=""
                  />
                  <p class="secondary small">
                    <strong>Paid Time Off</strong>
                  </p>
                </div>
                <div class="col-md-2 col-6">
                  <img
                    src={tagicon}
                    className="img-fluid"
                    style={iconStyle}
                    alt=""
                  />
                  <p class="secondary small">
                    <strong>Employee Discount Online</strong>
                  </p>
                </div>
                <div class="col-md-2 col-6">
                  <img
                    src={bankicon}
                    className="img-fluid"
                    style={iconStyle}
                    alt=""
                  />
                  <p class="secondary small">
                    <strong>Retirement Savings Plan</strong>
                  </p>
                </div>

                <div class="col-md-2 col-6 d-md-none">
                  <img
                    src={steps}
                    className="img-fluid"
                    style={iconStyle}
                    alt=""
                  />
                  <p class="secondary small">
                    <strong>Professional & Leadership Development</strong>
                  </p>
                </div>
                <div class="col-md-2 col-6 d-md-none">
                  <img
                    src={appleicon}
                    className="img-fluid"
                    style={iconStyle}
                    alt=""
                  />
                  <p class="secondary small">
                    <strong>Wellness Reimbursement</strong>
                  </p>
                </div>
                <div class="col-md-2 col-6 d-md-none">
                  <img
                    src={handicon}
                    className="img-fluid"
                    style={iconStyle}
                    alt=""
                  />
                  <p class="secondary small">
                    <strong>Adoption Assistance</strong>
                  </p>
                </div>
                <div class="col-md-2 col-6 d-md-none">
                  <img
                    src={iconStyleVolunteer}
                    className="img-fluid img-height-auto"
                    style={iconStyle}
                    alt=""
                  />
                  <p class="secondary small">
                    <strong>Company Sponsored Day of Volunteer Service</strong>
                  </p>
                </div>
              </div>
              <p>
                <strong class="primary font-italic">PLUS</strong> choice of
                health insurance plans, company-paid life/AD&D, short and long
                term disability, a variety of voluntary benefits and an employee
                assistance program.
              </p>
              <p>
                **Benefit offerings are subject to change and vary by role/level
                for each position. Respective waiting periods may apply to
                certain benefit offerings.
              </p>
              <div class="row mb-5 justify-content-center">
                <div class="col-md-4 col-12">
                  <a
                    href="https://childrensplace.wd1.myworkdayjobs.com/TCP03"
                    target="_blank"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-block"
                    >
                      Apply for International Jobs
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-5">
          <hr class="d-md-none border-top" />
        </div>
      </div>
      {/* End International More Info */}
    </div>{' '}
    {/* End Parent Collapse Container */}
  </div>
)

export default MBCareers
