import React from 'react'
import { Link } from 'gatsby'

import Layout from '../layouts/layout'
import SEO from '../components/seo'
import MBCareers from '../components/mbcareers'
import TitleHeader from '../components/titleheader'
import BorderedBox from '../components/borderedbox'
import Card from '../components/card'
import Slider from '../components/slider'
import SliderItem from '../components/slideritem'

// Images
import headerimage from '../assets/images/careers/Corprate_Careers_03.jpg'
import mbheaderimage from '../assets/images/careers/Corprate_CareersMB-Header_03.jpg'
import internimage from '../assets/images/careers/Going_places.jpg'
import corporateimage from '../assets/images/careers/Corporate.jpg'
import hqimage from '../assets/images/careers/Corprate_Careers-HQ_03.jpg'
import storesimage from '../assets/images/careers/Corprate_Careers-Stores_03.jpg'
import dcimage from '../assets/images/careers/Corprate_Careers-DC_11.jpg'
import intimageNew from '../assets/images/careers/HKO_Group_Photo.jpg'
import distroimage from '../assets/images/careers/Distribution.jpg'
import internationalimage from '../assets/images/careers/International.jpg'
import vacaicon from '../assets/images/careers/Corprate_Careers_Icon_06.jpg'
import tagicon from '../assets/images/careers/Corprate_Careers_09.jpg'
import calendaricon from '../assets/images/careers/Careers_US_Internship_Icon_09.jpg'
import bankicon from '../assets/images/careers/Corprate_Careers_Icon_03.jpg'
import appleicon from '../assets/images/careers/Corprate_Careers_Icon_17.jpg'
import handicon from '../assets/images/careers/Corprate_Careers_15.jpg'
import houseicon from '../assets/images/careers/Corprate_Careers_20.jpg'
import busicon from '../assets/images/careers/Corprate_Careers-Bus_Icon.jpg'
import steps from '../assets/images/careers/Corprate_Careers-Steps_Icon.jpg'
import coffeeicon from '../assets/images/careers/Corprate_Careers-Icons-HP_10.jpg'
import gymicon from '../assets/images/careers/Corprate_Careers-Icons-HP_06.jpg'
import storeimage from '../assets/images/careers/Stores.jpg'
import iconStyleVolunteer from '../assets/images/careers/iconStyleVolunteer.svg'

import esgPdf from '../assets/images/careers/ccpa.pdf'
import eVerify from '../assets/images/careers/eVerify_Message.pdf'

import { trackPageData, getPageDetails } from '../analytics/index'

const iconStyle = {
  height: '80px !important',
  marginBottom: '10px !important',
  verticalAlign: 'bottom',
}

class Careers extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Careers | The Children's Place Corporate Website"
          description="Looking for a career with The Children's Place? Learn more about who we're looking for and how to apply on The Children's Place Corporate Website."
        />
        {/* Page Title & Header Image */}
        <div class="row mb-4">
          <div class="col-12 text-center">
            <TitleHeader
              image={headerimage}
              mbheaderimage={mbheaderimage}
              title="MAKE OUR PLACE YOURS"
            />
            <div class="row justify-content-center">
              <div class=" col-12">
                <p>
                  We believe that diverse teams when managed in an inclusive
                  environment ‘outperform’ others. Our vision is to foster a
                  culture in which inclusion, equity, and diversity are a part
                  of our identity. A culture in which employees KNOW they are
                  represented, FEEL their voices will be heard, and TRUST that
                  we will all work together to provide and sustain these
                  conditions.
                </p>
                <p>
                  At The Children’s Place, we are committed to attracting top
                  talent and growing the company’s future leaders from within.
                  <br />
                  Interested in joining our team of over 10,000 associates in
                  our stores, distribution centers, corporate or international
                  offices? We offer:
                </p>
              </div>
              <div class="col-6 text-left">
                <ul class="list-inline list-bullet text-left">
                  <li class="corp-inside">
                    Competitive compensation and benefits
                  </li>
                  <li class="corp-inside">
                    A fast paced and engaging work environment
                  </li>
                  <li class="corp-inside">
                    A wide array of training and development opportunities,
                    including facilitator-led and online workshops
                  </li>
                </ul>
              </div>
              <div class=" col-12 mt-3">
                <p class="text-large">
                  <strong>
                    Click on the respective sections below to learn more about
                    each area of our business.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Title & Header Image */}
        {/* Leadership & Development Info */}
        {/* <div class="row mb-5 no-gutters">
          <div class="col-md-2 col-12 text-center pt-3">
            <img src={steps} className="img-fluid" alt="" />
          </div>
          <div class="col-md-10 col-12">
              <h2>PROFESSIONAL & LEADERSHIP DEVELOPMENT</h2>
              <p>The Children's Place offers professional development opportunities to help increase knowledge and skill, and improve performance. Training opportunities for both leaders and associates include leadership development, management, compliance, technical software, and behavioral competencies. Course availability varies by location and job function.</p>
          </div>
        </div> */}
        {/* Leadership & Development Info */}
        <div id="collapseCareers" class="d-none d-md-block">
          {' '}
          {/* Parent Collapse Container */}
          <div class="row mb-5">
            <div id="corporate" class="col-md-6 col-sm-12">
              <Card
                classname="mb-border-bottom"
                image={corporateimage}
                title="CORPORATE"
                description="Our corporate office, located 5 miles from Midtown Manhattan, offers opportunities to collaborate with industry leaders to shape the future of our brands."
              >
                <button
                  class="btn btn-primary more-less"
                  type="button"
                  data-toggle="collapse"
                  data-target="#corporateMore"
                  aria-expanded="false"
                  aria-controls="corporateMore"
                ></button>
              </Card>
            </div>
            <div id="stores" class="col-md-6 col-sm-12 d-md-block d-none ">
              <Card
                classname="mb-border-bottom"
                image={storeimage}
                title="STORES"
                description="Our fleet of stores, reaching across the US &amp; Canada, comprised of more than 10,000 associates deliver great experiences to our customers."
              >
                <button
                  class="btn btn-primary more-less"
                  type="button"
                  data-toggle="collapse"
                  data-target="#storeMore"
                  aria-expanded="false"
                  aria-controls="storeMore"
                ></button>
              </Card>
            </div>
          </div>
          {/* Corporate More Info */}
          <div
            class="row mb-5 collapse"
            id="corporateMore"
            data-parent="#collapseCareers"
          >
            <div class="col-12">
              <Slider id="careers-corporate-slider" classname="test">
                <SliderItem active={true}>
                  <div class="row no-gutters">
                    <div class="col-md-5 col-12 fill d-none d-lg-block">
                      <img src={hqimage} className="img-fluid" alt="" />
                    </div>
                    <div class="col-lg-7 col-md-12">
                      <BorderedBox classname="mb-n-border">
                        <div class="row mb-5 mt-5">
                          <div class="col-12">
                            <h2 class="mb-4  text-capitalize">
                              YOUR PLACE IN OUR CORPORATE OFFICE
                            </h2>
                            <p>
                              Careers at our Secaucus, New Jersey, headquarters
                              offer exciting experiences working alongside the
                              retail industry’s top talent to drive growth and
                              success for the company’s brands: The Children’s
                              Place, Gymboree, Sugar &amp; Jade and PJ PLACE.{' '}
                            </p>
                          </div>
                        </div>
                        <div class="row mb-5 justify-content-center">
                          <div class="col-md-6 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP01"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block"
                              >
                                APPLY FOR US CORPORATE JOBS
                              </button>
                            </a>
                          </div>
                        </div>
                      </BorderedBox>
                    </div>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="row no-gutters">
                    <div class="col-12">
                      <BorderedBox classname="mb-n-border">
                        <h2>CORPORATE BENEFITS</h2>
                        <div class="row justify-content-center">
                          <div class="col-6 col-md-2  pl-1 pr-1">
                            <div style={iconStyle}>
                              <img
                                src={vacaicon}
                                className="img-fluid  img-height-auto"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>Paid Time Off</strong>
                            </p>
                          </div>
                          <div class="col-md-2 col-6 pl-1 pr-1">
                            <div style={iconStyle}>
                              <img
                                src={calendaricon}
                                className="img-fluid  img-height-auto"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>Summer Fridays</strong>
                            </p>
                          </div>
                          <div class="col-md-2 col-6 pl-1 pr-1">
                            <div style={iconStyle}>
                              <img
                                src={steps}
                                className="img-fluid img-height-auto"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>
                                Professional &amp; Leadership Development
                              </strong>
                            </p>
                          </div>
                          <div class="col-md-2 col-6 pl-2 pr-2">
                            <div style={iconStyle}>
                              <img
                                src={tagicon}
                                className="img-fluid img-height-auto"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>
                                Employee Discount Online &amp; In-Store
                              </strong>
                            </p>
                          </div>
                          <div class="col-md-2 col-6 pl-2 pr-1">
                            <div style={iconStyle}>
                              <img
                                src={bankicon}
                                className="img-fluid img-height-auto"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>401K – up to 4% company match</strong>
                            </p>
                          </div>
                          <div class="col-md-2 col-6 pl-1 pr-1">
                            <div style={iconStyle}>
                              <img
                                src={gymicon}
                                className="img-fluid img-height-auto pt-4"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>Free onsite gym</strong>
                            </p>
                          </div>
                        </div>
                        <div class="row justify-content-center">
                          {/* <div class="col-md-2 col-6 pl-1 pr-1">
                            <div style={iconStyle}>
                              <img
                                src={appleicon}
                                className="img-fluid img-height-auto"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>
                                Wellness Reimbursement &amp; Weight Watchers
                              </strong>
                            </p>
                          </div> */}
                          <div class="col-md-2 col-6 pl-1 pr-1">
                            <div style={iconStyle}>
                              <img
                                src={handicon}
                                className="img-fluid img-height-auto"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>Adoption Assistance</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6 pl-1 pr-1">
                            <div style={iconStyle}>
                              <img
                                src={busicon}
                                className="img-fluid img-height-auto pt-2"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>
                                Free shuttle service to/from Secaucus train
                                station
                              </strong>
                            </p>
                          </div>
                          <div class="col-md-2 col-6 pl-1 pr-1">
                            <div style={iconStyle}>
                              <img
                                src={coffeeicon}
                                className="img-fluid img-height-auto"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>Onsite café &amp; coffee bar</strong>
                            </p>
                          </div>
                          <div class="col-md-2 col-6 pl-1 pr-1">
                            <div style={iconStyle}>
                              <img
                                src={houseicon}
                                className="img-fluid img-height-auto"
                                alt=""
                              />
                            </div>
                            <p class="secondary small">
                              <strong>
                                Free Backup Care (for kids, pets &amp; the
                                elderly)
                              </strong>
                            </p>
                          </div>
                        </div>
                        <p>
                          <strong class="primary font-italic">PLUS</strong>{' '}
                          choice of health insurance plans, company-paid
                          life/AD&amp;D, short and long term disability, a
                          variety of voluntary benefits and an employee
                          assistance program.
                        </p>
                        <p>
                          **Benefit offerings are subject to change and vary by
                          role/level for each position. Respective waiting
                          periods may apply to certain benefit offerings.
                        </p>
                        <div class="row mb-2 justify-content-center">
                          <div class="col-md-5 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP01"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block"
                              >
                                APPLY FOR US CORPORATE JOBS
                              </button>
                            </a>
                          </div>
                        </div>
                      </BorderedBox>
                    </div>
                  </div>
                </SliderItem>
              </Slider>
            </div>
          </div>
          {/* End Corporate More Info */}
          {/* Stores More Info */}
          <div
            class="row mb-5 collapse"
            id="storeMore"
            data-parent="#collapseCareers"
          >
            <div class="col-12">
              <Slider id="careers-store-slider" classname="test">
                <SliderItem active={true}>
                  <div class="row no-gutters">
                    <div class="col-5 fill d-none d-lg-block">
                      <img src={storesimage} className="img-fluid" alt="" />
                    </div>
                    <div class="col-lg-7 col-md-12">
                      <BorderedBox classname="mb-n-border">
                        <div class="row mb-5 mt-5">
                          <div class="col-12">
                            <h2 class="mb-4  text-capitalize">
                              YOUR PLACE IN OUR STORES
                            </h2>
                            <p>
                              The Children’s Place stores make shopping for kids
                              and families fast, easy, fun and affordable! We
                              are the PLACE where big fashion meets little
                              prices. Store Managers and Stores Leads, as well
                              as Part-time, Full-time and Seasonal Associate
                              opportunities are available within our fleet of
                              stores across the US &amp; Canada.
                            </p>
                          </div>
                        </div>
                        <div class="row mb-5">
                          <div class="col-lg-6 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP02"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block"
                              >
                                APPLY FOR US STORE JOBS
                              </button>
                            </a>
                          </div>
                          <div class="col-lg-6 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP05"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block"
                              >
                                APPLY FOR CANADA STORE JOBS
                              </button>
                            </a>
                          </div>
                          <div class="col-lg-12 col-12">
                            <br />
                            <br />
                            <a target="_blank" href={esgPdf}>
                              <p>
                                California Applicants click here for our Career
                                Privacy Notice
                              </p>
                            </a>
                            <br />
                            <p className="everify">
                              The Children’s Place participates in E-Verify in
                              the states of AL, AZ, FL, GA, LA, MS, NC, SC, TN,
                              and UT and will provide the federal government
                              with your Form I-9 information to confirm that you
                              are authorized to work in the U.S. Applicants in
                              AL, AZ, FL, GA, LA, MS, NC, SC, TN, and UT may
                              review the E-Verify and Right to Work postings by
                              clicking{' '}
                              <a target="_blank" href={eVerify}>
                                here
                              </a>
                              .
                            </p>
                          </div>
                        </div>
                      </BorderedBox>
                    </div>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="row no-gutters">
                    <div class="col-12">
                      <BorderedBox classname="mb-n-border">
                        <h2>STORE BENEFITS</h2>
                        {/* <p><strong>Eligible employees can participate in our comprehensive benefits program which includes the following:</strong></p> */}
                        <div class="row justify-content-center">
                          <div class="col-md-3 col-6">
                            <img
                              src={vacaicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Paid Time Off</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={tagicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>
                                Employee Discount Online &amp; In-Store
                              </strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={bankicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>401K – up to 4% company match</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6 d-md-none">
                            <img
                              src={appleicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Wellness Reimbursement</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6 d-md-none">
                            <img
                              src={handicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Adoption Assistance</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6 d-md-none">
                            <img
                              src={houseicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>
                                Free Backup Care (for kids, pets &amp; the
                                elderly)
                              </strong>
                            </p>
                          </div>
                        </div>
                        <div class="row justify-content-center d-md-flex d-none">
                          <div class="col-md-3 col-6">
                            <img
                              src={appleicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Wellness Reimbursement</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={handicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Adoption Assistance</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={houseicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>
                                Free Backup Care (for kids, pets &amp; the
                                elderly)
                              </strong>
                            </p>
                          </div>
                        </div>
                        <p>
                          <strong class="primary font-italic">PLUS</strong>{' '}
                          choice of health insurance plans, company-paid
                          life/AD&amp;D, short and long term disability, a
                          variety of voluntary benefits and an employee
                          assistance program.
                        </p>
                        <p>
                          **Benefit offerings are subject to change and vary by
                          role/level for each position. Respective waiting
                          periods may apply to certain benefit offerings.
                        </p>
                        <div class="row justify-content-center">
                          <div class="col-lg-4 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP02"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block"
                              >
                                APPLY FOR US STORE JOBS
                              </button>
                            </a>
                          </div>
                          <div class="col-lg-4 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP05"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block"
                              >
                                APPLY FOR CANADA STORE JOBS
                              </button>
                            </a>
                          </div>
                        </div>
                      </BorderedBox>
                    </div>
                  </div>
                </SliderItem>
                {/* <SliderItem >
                  <div class="row no-gutters">
                    <div class="col-5 fill d-none d-lg-block">
                      <img style={{ marginTop: '10%', minHeight: 'auto' }} src={msep} className="img-fluid img-mil-height additional-img" alt="" />
                    </div>
                    <div class="col-lg-7 col-md-12">
                      <BorderedBox classname="mb-n-border">
                        <div class="row mb-5 mt-5">
                          <div class="col-12">
                            <h2 class="mb-4  text-capitalize">SUPPORTING MILITARY SPOUSES
</h2>
                            <p>The Children’s Place is a proud partner of the Military Spouse Employment Partnership (MSEP).  We are committed to providing opportunities for military spouses and maintaining employment status for spouses as they relocate.</p>

                          </div>
                        </div>
                        <div class="row mb-5">
                          <div class="col-lg-6 col-12">
                            <a href="https://childrensplace.wd1.myworkdayjobs.com/TCP02" target="_blank">
                              <button type="button" class="btn btn-outline-primary btn-block">APPLY FOR US STORE JOBS</button>
                            </a>
                          </div>
                          <div class="col-lg-6 col-12">
                            <a href="https://childrensplace.wd1.myworkdayjobs.com/TCP05" target="_blank">
                              <button type="button" class="btn btn-outline-primary btn-block">APPLY FOR CANADA STORE JOBS</button>
                            </a>
                          </div>
                          <div class="col-lg-12 col-12">
                            <br />
                            <br />
                            <a target="_blank" href={ccpa}><p>California Applicants click here for our Career Privacy Notice</p></a>

                          </div>
                        </div>
                      </BorderedBox>
                    </div>
                  </div>
                </SliderItem> */}
              </Slider>
            </div>
          </div>
          {/* End Stores More Info */}
          <div class="row mb-5">
            <div id="distribution" class="col-md-6 col-sm-12 ">
              <Card
                classname="mb-border-bottom"
                image={distroimage}
                title="DISTRIBUTION CENTER"
                description="Our state-of-the-art distribution centers process over 250 million packages a year!"
              >
                <button
                  class="btn btn-primary more-less"
                  type="button"
                  data-toggle="collapse"
                  data-target="#distributionMore"
                  aria-expanded="false"
                  aria-controls="distributionMore"
                ></button>
              </Card>
            </div>
            <div
              id="international"
              class="col-md-6 col-sm-12 d-md-block d-none"
            >
              <Card
                classname="mb-border-bottom"
                image={internationalimage}
                title="INTERNATIONAL"
                description="We have field teams working in more than 20 countries in Sourcing, Quality Assurance, IT & more!"
              >
                <button
                  class="btn btn-primary more-less"
                  type="button"
                  data-toggle="collapse"
                  data-target="#internationalMore"
                  aria-expanded="false"
                  aria-controls="internationalMore"
                ></button>
              </Card>
            </div>
          </div>
          {/* Distribution More Info */}
          <div
            class="row mb-5 collapse"
            id="distributionMore"
            data-parent="#collapseCareers"
          >
            <div class="col-12">
              <Slider id="careers-distro-slider" classname="test">
                <SliderItem active={true}>
                  <div class="row no-gutters">
                    <div class="col-5 fill d-none d-lg-block">
                      <img src={dcimage} className="img-fluid" alt="" />
                    </div>
                    <div class="col-lg-7 col-md-12">
                      <BorderedBox classname="mb-n-border">
                        <div class="row mb-5 mt-5">
                          <div class="col-12">
                            <h2 class="mb-4  text-capitalize">
                              YOUR PLACE IN OUR DISTRIBUTION CENTERS
                            </h2>
                            <p>
                              Our state-of-the-art Distribution Centers in Fort
                              Payne, Alabama &amp; Ontario, Canada supply our
                              stores across North America with merchandise as
                              well as fulfill all online orders.{' '}
                            </p>
                          </div>
                        </div>
                        <div class="row mb-5 justify-content-center">
                          <div class="col-md-6 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP04"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block text-capitalize"
                              >
                                Apply for US Distribution Jobs
                              </button>
                            </a>
                          </div>
                          <div class="col-md-6 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP06"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block text-capitalize"
                              >
                                Apply for Canada Distribution Jobs
                              </button>
                            </a>
                          </div>

                          <br />
                          <br />
                          <p className="everify">
                            The Children’s Place participates in E-Verify in the
                            states of AL, AZ, FL, GA, LA, MS, NC, SC, TN, and UT
                            and will provide the federal government with your
                            Form I-9 information to confirm that you are
                            authorized to work in the U.S. Applicants in AL, AZ,
                            FL, GA, LA, MS, NC, SC, TN, and UT may review the
                            E-Verify and Right to Work postings by clicking{' '}
                            <a target="_blank" href={eVerify}>
                              here
                            </a>
                            .
                          </p>
                        </div>
                      </BorderedBox>
                    </div>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="row no-gutters">
                    <div class="col-12">
                      <BorderedBox classname="mb-n-border">
                        <h2>DISTRIBUTION CENTER BENEFITS</h2>
                        <div class="row justify-content-center">
                          <div class="col-md-3 col-6">
                            <img
                              src={vacaicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Generous PTO</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={steps}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>
                                Professional & Leadership Development
                              </strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={tagicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>
                                Employee Discount Online & In-Store
                              </strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={bankicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>401K – up to 4% company match</strong>
                            </p>
                          </div>
                        </div>
                        <div class="row justify-content-center d-none d-md-flex">
                          <div class="col-md-3 col-6">
                            <img
                              src={appleicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Wellness Reimbursement</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={handicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Adoption Assistance</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={houseicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>
                                Free Backup Care (for kids, pets & the elderly)
                              </strong>
                            </p>
                          </div>
                        </div>
                        <p>
                          <strong class="primary font-italic">PLUS</strong>{' '}
                          choice of health insurance plans, company-paid
                          life/AD&D, short and long term disability, a variety
                          of voluntary benefits and an employee assistance
                          program.
                        </p>
                        <p>
                          **Benefit offerings are subject to change and vary by
                          role/level for each position. Respective waiting
                          periods may apply to certain benefit offerings.
                        </p>
                        <div class="row mb-5 justify-content-center">
                          <div class="col-md-4 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP04"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block"
                              >
                                Apply for US Distribution Jobs
                              </button>
                            </a>
                          </div>
                          <div class="col-md-4 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP06"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block"
                              >
                                Apply for Canada Distribution Jobs
                              </button>
                            </a>
                          </div>
                        </div>
                      </BorderedBox>
                    </div>
                  </div>
                </SliderItem>
              </Slider>
            </div>
          </div>
          {/* End Distribution More Info */}
          {/* International More Info */}
          <div
            class="row mb-5 collapse"
            id="internationalMore"
            data-parent="#collapseCareers"
          >
            <div class="col-12">
              <Slider id="careers-international-slider" classname="test">
                <SliderItem active={true}>
                  <div class="row no-gutters">
                    <div class="col-md-5 col-12 fill d-none d-lg-block">
                      <img src={intimageNew} className="img-fluid" alt="" />
                    </div>
                    <div class="col-lg-7 col-md-12">
                      <BorderedBox classname="mb-n-border">
                        <div class="row mb-5 mt-5">
                          <div class="col-12">
                            <h2 class="mb-4  text-capitalize">
                              YOUR PLACE AROUND THE WORLD
                            </h2>
                            <p>
                              Our International teams work in countries across
                              the globe supporting key initiatives to drive the
                              success of our company globally. We have offices
                              in many major cities, including Hong Kong SAR,
                              China; Shanghai, China; Hyderabad, India; Addis
                              Ababa, Ethiopia and Nairobi, Kenya.
                            </p>
                          </div>
                        </div>
                        <div class="row mb-4 justify-content-center">
                          <div class="col-md-6 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP03"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block"
                              >
                                Apply for International Jobs
                              </button>
                            </a>
                          </div>
                        </div>
                      </BorderedBox>
                    </div>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="row no-gutters">
                    <div class="col-12">
                      <BorderedBox classname="mb-n-border">
                        <h2>INTERNATIONAL BENEFITS</h2>
                        <div class="row justify-content-center">
                          <div class="col-md-3 col-6">
                            <img
                              src={vacaicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Paid Time Off</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={tagicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Employee Discount Online</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6">
                            <img
                              src={bankicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Retirement Savings Plan</strong>
                            </p>
                          </div>

                          <div class="col-md-3 col-6 d-md-none">
                            <img
                              src={steps}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>
                                Professional &amp; Leadership Development
                              </strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6 d-md-none">
                            <img
                              src={appleicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Wellness Reimbursement</strong>
                            </p>
                          </div>
                          <div class="col-md-3 col-6 d-md-none">
                            <img
                              src={handicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Adoption Assistance</strong>
                            </p>
                          </div>
                        </div>
                        <div class="row justify-content-center">
                          <div class="col-md-2 col-6 d-none d-md-block">
                            <img
                              src={steps}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>
                                Professional &amp; Leadership Development
                              </strong>
                            </p>
                          </div>
                          <div class="col-md-2 col-6 d-none d-md-block">
                            <img
                              src={appleicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Wellness Reimbursement</strong>
                            </p>
                          </div>
                          <div class="col-md-2 col-6 d-none d-md-block">
                            <img
                              src={handicon}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>Adoption Assistance</strong>
                            </p>
                          </div>
                          <div class="col-md-2 col-6">
                            <img
                              src={iconStyleVolunteer}
                              className="img-fluid img-height-auto"
                              style={iconStyle}
                              alt=""
                            />
                            <p class="secondary small">
                              <strong>
                                Company Sponsored Day of Volunteer Service
                              </strong>
                            </p>
                          </div>
                        </div>
                        <p>
                          <strong class="primary font-italic">PLUS</strong>{' '}
                          choice of health insurance plans, company-paid
                          life/AD&amp;D, short and long term disability, a
                          variety of voluntary benefits and an employee
                          assistance program.
                        </p>
                        <p>
                          **Benefit offerings are subject to change and vary by
                          role/level for each position. Respective waiting
                          periods may apply to certain benefit offerings.
                        </p>
                        <div class="row mb-5 justify-content-center">
                          <div class="col-md-4 col-12">
                            <a
                              href="https://childrensplace.wd1.myworkdayjobs.com/TCP03"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-block"
                              >
                                Apply for International Jobs
                              </button>
                            </a>
                          </div>
                        </div>
                      </BorderedBox>
                    </div>
                  </div>
                </SliderItem>
              </Slider>
            </div>
          </div>
          {/* End International More Info */}
        </div>{' '}
        {/* End Parent Collapse Container */}
        <div class="d-md-none">
          <MBCareers />
        </div>
        {/* Internship */}
        <div class="row mb-5 no-gutters">
          <div class="col-md-4 col-sm-12 fill intern-logo">
            <img src={internimage} className="img-fluid" alt="" />
          </div>
          <div class="col-lg-8 col-md-12 text-center">
            <BorderedBox classname="mb-n-border">
              <h2>INTERNSHIP PROGRAMS</h2>
              <p>
                Every Summer, The Children’s Place runs a comprehensive 10-week
                Internship Program in both our Corporate Office and Hong Kong
                Office. Recruitment efforts begin in September of each year. Be
                on the look out for new updates at that time.
              </p>

              {/* <div class="row">
                <div class="col-lg-6 col-md-12">
                  <Link to="https://childrensplace.wd1.myworkdayjobs.com/en-US/TCP01/details/Corporate-Internship-Summer-2023--All-Majors-_R000024213?q=internship">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-block"
                    >
                      US INTERNSHIPS
                    </button>
                  </Link>
                </div>
                <div class="col-lg-6 col-md-12">
                  <Link to="hong-kong-internships">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-block"
                    >
                      HONG KONG INTERNSHIPS
                    </button>
                  </Link>
                </div>
              </div> */}
            </BorderedBox>
          </div>
        </div>
        {/* End Internship */}
      </Layout>
    )
  }
}

export default Careers
